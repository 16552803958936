import { Pagination, Spin } from "antd";
import { get, map } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatDecimalNumber } from "../../../utilities/common-function";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// import getLeaderRewards from "../../../newApi/rewardExplore/getLeaderRewards";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { defaultPopulatedFieldName } from "../../../newApi";
import getUserSummary from "../../../newApi/pairing/getUserSummary";
import getUserTransactions from "../../../newApi/pairing/getUserTransactions";
import { numberDecimal } from "../../../utilities/startUp";
import RequirePairingAuthWrapper from "../../general/components/RequirePairingAuthWrapper";

const PAGE_SIZE = 10;

//let displayRobot = [];

// markup
const DirectReward = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  // let accessKey = get(props.user, "accessKey");
  const [todayData, setTodayData] = useState({});
  const [totalData, setTotalData] = useState({});
  //let profile = get(props.user.user, "profile");
  const user = get(props, "user.user");

  useEffect(() => {
    getData((page - 1) * PAGE_SIZE);
    getTotalData();
  }, []);

  useEffect(() => {
    getData((page - 1) * PAGE_SIZE);
  }, [page]);

  function getData(skip) {
    setLoading(true);

    getUserTransactions(PAGE_SIZE, skip, {
      userId: get(user, "pairingUser._id"),
      sourceType: 3,
      populate: [
        {
          service: "bonusleaders",
          foreignId: "bonusLeaderId",
          populate: [
            // {
            //   service: "rankings",
            //   foreignId: "rankingId",
            // },
            {
              service: "users",
              foreignId: "distributorId",
            },
          ],
        },
      ],
    })
      .then((res) => {
        // console.log(res);
        let data = get(res, "data");
        setTotal(get(res, "total"));
        setDataSource(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function getTotalData() {
    if (get(user, "pairingUser._id")) {
      // console.log("getData");
      getUserSummary("all", 0, {
        userId: get(user, "pairingUser._id"),
        dateFrom: moment().startOf("d").toDate(),
        dateTo: moment().endOf("d").toDate(),
      })
        .then((res) => {
          // console.log({ res });
          setTodayData(get(res, "data"));
        })
        .catch((err) => {
          console.log(err);
        });

      getUserSummary("all", 0, {
        userId: get(user, "pairingUser._id"),
      })
        .then((res) => {
          // console.log({ res });
          setTotalData(get(res, "data"));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const _renderProfit = () => {
    return (
      <div className="grid grid-cols-4 gap-2 bg-main-color-gradient rounded-3xl mx-4 pt-2 pb-1">
        <div className="col-span-2 ">
          <div className="mb-2  ">
            <div className="font-semibold text-center text-2xl pt-4">
              {formatDecimalNumber(
                get(todayData, "totalLeaderBonus") || 0,
                numberDecimal
              )}
            </div>
            <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
              {/* 今日盈利 <br /> */}
              {t("profitToday", sourceKey.pairing)}
            </div>
          </div>
        </div>

        <div className="col-span-2">
          <div className="mb-2  ">
            <div className="font-semibold text-center text-2xl pt-4">
              {formatDecimalNumber(
                get(totalData, "totalLeaderBonus") || 0,
                numberDecimal
              )}
            </div>
            <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
              {/* 总盈利 <br /> */}
              {t("totalProfit", sourceKey.pairing)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderList = () => {
    return (
      <div className="greybox-bg-color m-4 rounded-b-3xl pt-2">
        {map(dataSource, (item, index) => {
          return (
            <div
              key={index}
              className=" mx-2 pb-2"
              // style={{
              //   borderColor: "#20262f",
              //   borderBottomColor: "white",
              // }}
            >
              <div className="px-4">
                <div className="flex items-center">
                  <span className={`inline-block `}>
                    {t("distributor", sourceKey.pairing)}
                  </span>
                  <span className="inline-block mx-2">:</span>
                  <span className="inline-block w-1/2 ">
                    {get(
                      item,
                      `${defaultPopulatedFieldName(
                        "bonusLeaderId"
                      )}.${defaultPopulatedFieldName("distributorId")}.username`
                    )}
                  </span>
                </div>
                {/* <div className="flex items-center">
                  <span className={`inline-block w-1/3 font-semibold`}>
                    Level Sponsor Bonus
                  </span>
                  <span className="inline-block mx-2">:</span>
                  <span className="inline-block w-1/2 ">{item.getLevel}</span>
                </div> */}
                <div className="flex justify-between">
                  <span className="flex items-center">
                    {get(item, "createdAt")
                      ? moment(get(item, "distributeDate")).format(
                          "DD/MM/YYYY hh:mm:ss A"
                        )
                      : ""}
                  </span>
                  <span className="flex items-center">
                    + {formatDecimalNumber(item.amount, numberDecimal)}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <RequirePairingAuthWrapper>
            <NavHeader
              showBack={true}
              suffix={
                <React.Fragment>
                  <div className="justify-end flex items-center">
                    {t("leaderReward", sourceKey.pairing)}
                  </div>
                </React.Fragment>
              }
            >
              <div
                className=""
                // style={{height:"105vh"}}
                // style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
              >
                <div className="">{_renderProfit()}</div>
                {_renderList()}
                <div className="flex justify-end items-center my-5 mx-5">
                  <span className="inline-block ">
                    <Pagination
                      simple
                      size="small"
                      total={total}
                      pageSize={PAGE_SIZE}
                      current={page}
                      showSizeChanger={false}
                      responsive={true}
                      onChange={(page) => {
                        setPage(page);
                      }}
                    />
                  </span>
                </div>
              </div>
            </NavHeader>
          </RequirePairingAuthWrapper>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateActiveId,
};
export default connect(mapStateToProps, mapDispatchToProps)(DirectReward);
